import React from 'react';

import { Box, IconButton, Select, MenuItem, FormControl, InputLabel, Typography, Autocomplete, TextField } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';



function QuestionToolbar(props) {



    const criteriaData = props.data;
    const handleQVariableSelected = props.onQVariableSelect;
    const handleShowHideAnswerClicked = props.onShowHideAnswer;
    const freeTextMode = props.freeTextMode;

    const handleRemoveClicked = props.onRemove;
    const handleUndoClicked = props.onUndo;
    const handleAddClicked = props.onAdd;
    const handleEditClicked = props.onEdit;
    const index = props.index;



    const handleUpClick = () => props.onUpDown(-1);
    const handleDownClick = () => props.onUpDown(1);
    //const handleDuplicate = props.onDuplicate;
    const answerHidden = props.answerHidden;
    const editMode = props.editMode;
    const selectedMode = props.selectedMode;



    const changeQVariableSelected = (e) => {
        handleQVariableSelected(JSON.parse(e.target.value), null, 'CONTEXT');
    }



    const clickShowHideAnswer = (e) => {
        handleShowHideAnswerClicked(e.target.id);
    }



    const clickUndo = (e) => {
        handleUndoClicked(e.target.id);
    }

    const clickAdd = (e) => {
        handleAddClicked(e.target.id);
    }

    const clickRemove = (e) => {
        handleRemoveClicked(e.target.id);
    }

    const clickEdit = (e) => {

        handleEditClicked(e.target.id);
    }


    const generateSelectedContextQVariable = (contextQVariable) => {

        let contextQVariableClone = JSON.parse(JSON.stringify(contextQVariable));
        contextQVariableClone.imageQVariable = null;
        return JSON.stringify(contextQVariableClone);
    }


    const generateVariableSelect = () => {

        if (criteriaData.contextQVariableList) {

            var optionList = criteriaData.contextQVariableList.map((item) => {

                let itemLabel = item.label;

                return (<MenuItem key={itemLabel} value={JSON.stringify(item)} >
                    {itemLabel}
                </MenuItem>
                )
            });

            return (

                <FormControl sx={{ minWidth: 200, marginRight: '20px' }}>
                    <InputLabel id="context-label" size="small">Select</InputLabel>
                    <Select autoWidth label="Select" value={criteriaData.questionParams.contextQVariable ? generateSelectedContextQVariable(criteriaData.questionParams.contextQVariable) : ''} size='small' sx={{ backgroundColor: 'white' }} labelId='context-label' variant="outlined" onChange={changeQVariableSelected}>
                        {optionList}

                    </Select >
                </FormControl >
            )
        }

    }

    const changeEntitySelected = (e, value) => {

        if (value) {
            handleQVariableSelected(value, null, 'ENTITY');
        }
    }

    const qeToQVariable = (qe) => {

        let qv = { 'label': qe.label, 'idMapping': qe.idMapping };
        return qv;

    }


    const generateEntitySelect = () => {

        let QEList = criteriaData.quizzlerEntityList;
        let QEQVariableList = QEList.map(qe => qeToQVariable(qe));
        let selectedQE = qeToQVariable(criteriaData.questionParams.quizzlerEntity);

        if (QEList) {

            return (

                <FormControl sx={{ minWidth: 200, marginRight: '20px' }}>


                    <Autocomplete
                        disablePortal
                        size="small"
                        options={QEQVariableList}

                        sx={{ backgroundColor: 'white' }}
                        value={selectedQE}
                        renderInput={(params) => <TextField  {...params} label="Select" />}

                        onChange={changeEntitySelected}
                    />


                </FormControl >

            )
        }

    }


    return (

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

            <Box>{selectedMode && <Typography variant="h3">{index + 1}</Typography>}</Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                {editMode && !freeTextMode && generateEntitySelect()}
                {editMode && !freeTextMode && generateVariableSelect()}

                {editMode && !selectedMode && <IconButton aria-label="undo" size="small" onClick={clickUndo} ><UndoOutlinedIcon fontSize="large" /></IconButton>}

                <IconButton aria-label="edit" size="small" onClick={clickEdit} ><EditOutlinedIcon fontSize="large" /></IconButton>
                <IconButton aria-label="show/hide" size="small" onClick={clickShowHideAnswer}>{answerHidden ? <VisibilityOffOutlinedIcon fontSize="large" /> : <VisibilityOutlinedIcon fontSize="large" />}</IconButton>

                {selectedMode && <IconButton aria-label="up" size="small" onClick={handleUpClick}><ArrowCircleUpOutlinedIcon fontSize="large" /></IconButton>}
                {selectedMode && <IconButton aria-label="down" size="small" sx={{ marginLeft: '-10px' }} onClick={handleDownClick}><ArrowCircleDownOutlinedIcon fontSize="large" /></IconButton>}

                {!selectedMode && <IconButton aria-label="add" size="small" onClick={clickAdd}><AddCircleOutlinedIcon fontSize="large" color="success" /></IconButton>}
                {selectedMode && <IconButton aria-label="remove" size="small" onClick={clickRemove}><RemoveCircleOutlinedIcon fontSize="large" sx={{ color: 'red' }} /></IconButton>}

            </Box>



        </Box >

    )

}

export default QuestionToolbar;

