import React, { useState, useRef } from 'react';
import { config } from "../Const.js";
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { executeFetch } from '../Util.js';

function SearchBox({ endPoint, onEntitySelected, resultMappingFunction }) {


    const [options, setOptions] = useState([]);

    const previousController = useRef();

    const onInputChange = (event, value, reason) => {
        if (value) {

            getData(value);
        } else {
            setOptions([]);
        }
    };

    const getData = (searchTerm) => {
        if (previousController.current) {

            previousController.current.abort();

        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;

        const url = `${config.SERVER_URL}/api/v1/search/${endPoint}?q=${searchTerm}`;
        let headers = {
            "Content-Type": "application/json",
            Accept: "application/json"
        };

        const responseHandler = (results) => {
            const updatedOptions = results.map(resultMappingFunction);
            setOptions(updatedOptions);
        }

        executeFetch(url, "get", headers, null, signal, responseHandler);


    };

    return (
        <Autocomplete

            sx={{ mt: '20px', width: '100%' }}
            options={options}
            onInputChange={onInputChange}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            onChange={(e, result) => onEntitySelected(result)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
                <TextField {...params} label="Search Entity" variant="outlined" />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {option.label}

                        <Typography variant="body2" color="purple">
                            {option.type?.toLowerCase()}
                        </Typography>

                    </Box>
                </li>
            )}
        />


    )

};

export default SearchBox;
