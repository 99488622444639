import React from 'react';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Typography, Paper } from '@mui/material';

import { generateImageUrlFromCDN } from '../Util';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';



export default function CategoryPageQuizList(props) {

    const quizList = props.quizList;
    const notifyAdd = props.onAdd;

    return (
        <Box sx={{ height: '100%' }}>


            <Typography variant='h6'>Available</Typography>

            <Paper elevation={10} sx={{ height: '92%', overflowY: 'auto', pl: '20px', pt: '15px' }}>

                <List>

                    {quizList.map((quiz) => {

                        return (

                            <ListItem

                                sx={{ mt: '5px' }}
                                key={quiz.id}
                                secondaryAction={
                                    <IconButton aria-label="add" size="small" onClick={() => notifyAdd(quiz)}><AddCircleOutlinedIcon fontSize="large" color="success" /></IconButton>
                                }
                                disablePadding
                            >

                                <ListItemAvatar>
                                    <Avatar
                                        alt={quiz.label}
                                        src={generateImageUrlFromCDN(quiz.startImageMetadata)}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={quiz.label} sx={{ '.MuiListItemText-secondary': { color: 'orange', fontSize: '0.7em', fontWeight: 700 } }} secondary={quiz.settings?.popular ? 'Popular' : ''} />

                            </ListItem>
                        );
                    })}



                </List>
            </Paper>
        </Box >


    );

};
