import React from 'react';
import { useState, useEffect } from 'react';
import playIcon from '../icons/video-play.png';
import pauseIcon from '../icons/video-pause.png';


function AudioPlayer({ audioUrl }) {


    const [isPlaying, setIsPlaying] = useState(false)
    const [audioPlayer, setAudioPlayer] = useState(new Audio());

    const playPause = () => {

        if (!isPlaying) {
            audioPlayer.play();
        } else {
            audioPlayer.pause();
        }

        setIsPlaying(!isPlaying);

    };


    useEffect(() => {

        audioPlayer.pause();
        setIsPlaying(false);
        let audio = new Audio();
        audio.src = audioUrl;
        setAudioPlayer(audio);

        // eslint-disable-next-line     
    }, [audioUrl]);





    return (

        <img alt={audioUrl} width="48" src={isPlaying ? pauseIcon : playIcon} onClick={() => { playPause() }}></img>

    )

}


export default AudioPlayer;
