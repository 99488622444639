import React, { useState } from 'react';
import ImageCropper from './ImageCropper';
import { Box, Dialog } from '@mui/material';


function GalleryImage(props) {


    const imd = props.imd;

    const [zoomPopupMode, setZoomPopupMode] = useState(false)
    const [zoomPopupImage, setZoomPopupImage] = useState(null)

    const handleCropAreaSelected = props.onSelect;
    const lockAspect = props.lockAspect;

    const handleImageClicked = (imd) => {

        setZoomPopupImage(imd);
        setZoomPopupMode(true);
    }

    const handleImageError = (e) => {

        console.log('error');
        e.currentTarget.onerror = null;
        e.currentTarget.parentElement.parentElement.style.display = 'none';
    }

    const generateThumbUrl = (imageUrl) => {

        if (imageUrl == null) {
            return null;
        }

        let fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
        let thumbUrl = imageUrl;

        if (imageUrl.indexOf("wikimedia") > -1) {
            thumbUrl = imageUrl.replace("https://upload.wikimedia.org/wikipedia/commons/", "https://upload.wikimedia.org/wikipedia/commons/thumb/");
            thumbUrl = thumbUrl + "/" + 180 + "px-" + fileName;
        }

        return thumbUrl;

    }


    return (

        <Box>

            <Box>
                <img style={{ borderRadius: '5px' }} onError={handleImageError} alt={imd.label} src={generateThumbUrl(imd.url)} width={180} key={imd.url} onClick={() => handleImageClicked(imd)} ></img>
            </Box>


            <Dialog open={zoomPopupMode} onClose={() => setZoomPopupMode(false)} PaperProps={{ sx: { width: 700, overflowY: 'scroll', height: 800, padding: '10px' } }}>
                <ImageCropper lockAspect={lockAspect} imd={zoomPopupImage} onSelect={handleCropAreaSelected}></ImageCropper>
            </Dialog>
        </Box >


    )

};

export default GalleryImage;
