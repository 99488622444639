import React from 'react';
import QuestionContainer from './QuestionContainer';
import 'reactjs-popup/dist/index.css';
import { Box, Button } from '@mui/material';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';




function QuestionCanvas(props) {


    const questionData = props.questionData;

    const notifyDeleteQuestion = props.onDeleteQuestion;
    const notifyChangeQuestion = props.onChangeQuestion;
    const notifyUpDownQuestion = props.onUpDownQuestion;
    const notifyCreateNewQuestion = props.onCreateNewQuestion;


    let questionIndex = 0;

    var qList = questionData.map(function (q) {

        return <Box sx={{ mt: '20px', backgroundColor: '#e9eef1' }}>
            <QuestionContainer index={questionIndex++} key={q.question.templateId} onUpDown={(dir) => notifyUpDownQuestion(dir, q)} selectedMode={true} data={q} onRemove={notifyDeleteQuestion} onDataChange={notifyChangeQuestion} ></QuestionContainer >
        </Box>

    });

    return (

        <Box sx={{ height: '100%', overflowY: 'auto', pl: '10px', pr: '20px' }}>
            <Button variant="contained" sx={{ marginRight: '5%' }} onClick={notifyCreateNewQuestion} >Create New</Button>
            <Box>
                {qList}
            </Box>


        </Box >
    )
};

export default QuestionCanvas;