import { config } from "./Const.js";
import placeHolderImage from './icons/placeholder_image.jpg';

export const generateImageUrl = (url, x, y, width, height) => {

    return (config.SERVER_URL + "/api/v1/image/crop/" + encodeURIComponent(url) + "/" + x + "/" + y + "/" + width + "/" + height);

}




export const generateImageUrlFromImd = (imd) => {

    if (imd != null) {
        if (imd.crop == null) {
            return imd.url;
        } else {
            return generateImageUrl(imd.url, imd.crop.x, imd.crop.y, imd.crop.width, imd.crop.height);
        }
    }
}

export const generateImageUrlFromCDN = (imd) => {

    if (imd != null && imd.storedImageFileName != null) {

        return config.CDN_URL + "/" + imd.storedImageFileName;

    } else {
        return placeHolderImage;
    }
}

export const handleResponse = (res, errorHandler) => {
    if (!res.ok) {
        let status = res.status;
        if (status === 401) {

            localStorage.removeItem("token");

            if (errorHandler) {
                errorHandler();
            } else {
                window.location.replace("/");
            }

        }
    } else {

        let contentType = res.headers.get("content-type");

        if (contentType === "application/json") {

            return res.json();
        } else {
            return res.text();
        }

    }
}

export const executeFetch = (url, method, headers, body, signal, responseDataHandler, errorDataHandler) => {

    if (!headers) headers = {};
    let token = localStorage.getItem('token');

    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    try {

        fetch(url, {
            signal: signal,
            body: body,
            method: method ? method : "get",
            headers: headers

        })
            .then((res) => {
                return handleResponse(res, errorDataHandler);

            })
            .then(responseDataHandler);
    } catch (e) {
        if (e.name === 'AbortError') {
            return;
        }
        //console.error('Unexpected error:', e);
    }

};