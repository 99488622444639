import React, { useState, useEffect } from 'react';

import GalleryImage from './GalleryImage';
import InputContainer from '../InputContainer';
import { Box, Paper, ImageList, ImageListItem, Pagination, CircularProgress, Button } from '@mui/material';
import { config } from "../Const.js";
import { executeFetch } from '../Util';



function Gallery(props) {



    const isValidImd = (imd) => {
        let url = imd.url;
        return url != null && (url.endsWith('png') || url.endsWith('PNG') || url.endsWith('jpg') || url.endsWith('JPG') || url.endsWith('jpeg') || url.endsWith('PNG'));
    }

    const getValidImdList = (imdList) => {
        return imdList ? imdList.filter(imd => isValidImd(imd)) : [];
    }

    const dataUrl = props.dataUrl;
    const lockAspect = props.lockAspect;
    const searchDataUrl = config.SERVER_URL + '/api/v1/search/image?q=';

    const [pageNum, setPageNum] = useState(1);
    const [loading, setLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState(null);
    const [searchQueryTemp, setSearchQueryTemp] = useState(null);
    const [imdSlice, setImdSlice] = useState([]);
    const [pageCount, setPageCount] = useState(-1);
    const pageSize = 20;
    const columnCount = 5;

    const handleImageSelected = props.onSelect;
    const uploadUrl = config.SERVER_URL + "/api/v1/image/upload";

    const handlePageChange = (e, page) => {

        setPageNum(page);

    }

    const handleInputChange = (value) => {

        setSearchQueryTemp(value);
    }

    const handleUploadImageSelected = (e) => {

        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setLoading(true);

        let responseDataHandler = (imd) => {

            setImdSlice([imd]);
            setLoading(false);
        }

        executeFetch(uploadUrl, "post", null, formData, null, responseDataHandler, null)
    };


    const fetchImageMetadataListByQuery = () => {

        setPageNum(1);
        setSearchQuery(searchQueryTemp)
    }


    useEffect(() => {

        let selectedDataUrl = searchQuery ? (searchDataUrl + searchQuery) : dataUrl;

        if (selectedDataUrl) {

            setLoading(true);
            setImdSlice([]);

            let url = selectedDataUrl + '&pageNum=' + pageNum + '&pageSize=' + pageSize;
            let responseDataHandler = (result) => {
                setLoading(false);
                let validImdList = getValidImdList(result.imdList);
                setImdSlice(validImdList);
                setPageCount(Math.ceil(result.resultCount / pageSize));
            }

            executeFetch(url, "get", null, null, null, responseDataHandler)

        }

        // eslint-disable-next-line
    }, [searchQuery + pageNum]);

    return (

        <Box sx={{ height: '100%', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: '20px' }}>

                <Box sx={{ width: '60%', display: 'flex', gap: '10px' }}>
                    <InputContainer label='Search' onInputChange={handleInputChange} onEnter={fetchImageMetadataListByQuery}></InputContainer>
                    <Button variant="contained" onClick={fetchImageMetadataListByQuery}>Search</Button>
                    <Button variant="contained" sx={{ ml: '10px' }} color="secondary" onChange={handleUploadImageSelected} component="label">
                        Upload
                        <input hidden accept="image/*" multiple type="file" />
                    </Button>

                </Box>

                {(pageCount > 1) &&
                    <Pagination sx={{ width: '40%' }} count={pageCount} color="primary" page={pageNum} onChange={handlePageChange} />
                }

            </Box>


            <Paper elevation={5} sx={{ padding: '10px', height: '90%', overflowY: 'auto' }}>

                {imdSlice.length === 0 && loading && <Box sx={{ padding: '20px' }}><CircularProgress /></Box>}

                <ImageList variant="masonry" cols={columnCount} gap={5}>

                    {!loading && imdSlice.map((imd) => (
                        <ImageListItem key={'item_' + imd.url}>

                            <GalleryImage lockAspect={lockAspect} key={imd.title} onSelect={handleImageSelected} imd={imd}></GalleryImage>
                        </ImageListItem>
                    ))}
                </ImageList>

            </Paper>

        </Box >
    )


};

export default Gallery;
