
export const getCategoryPathByTitle = (title) => {

    let path;
    // potentially multiple identifiers for each category, one coming from last.fm tags, the other coming from url title
    switch (title) {

        case 'classic-rock':
        case 'classic rock':

            path = 'classic-rock';
            break;

        case 'country':
            path = 'country';
            break;

        case 'latin':
            path = 'latin';
            break;

        case 'rnb':
            path = 'rnb';
            break;

        case 'folk':
            path = 'folk';
            break;

        case 'hip-hop':
        case 'hip hop':
            path = 'hip-hop';
            break;

        case '60s':
            path = '60s';
            break;

        case '70s':
            path = '70s';
            break;

        case '80s':
            path = '80s';
            break;

        case '90s':
            path = '90s';
            break;

        case '2000s':
        case '00s':
            path = '2000s';
            break;

        case 'pop':
            path = 'pop';
            break;

        case 'alternative':
            path = 'alternative';
            break;

        case 'new-wave':
        case 'new wave':
            path = 'new-wave';
            break;

        case 'hard-rock':
        case 'hard rock':
            path = 'hard-rock';
            break;

        case 'k-pop':
            path = 'k-pop';
            break;

        default:

    }



    return path;
}




