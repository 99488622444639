import React from 'react';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, List, ListItem, IconButton, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, Typography, Paper } from '@mui/material';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { generateImageUrlFromCDN } from '../Util';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';





function CategoryPageSelectedList(props) {

    const quizList = props.quizList;

    const notifyRemove = props.onRemove;
    const notifyUp = props.onUp;
    const notifyDown = props.onDown;

    return (

        <Box sx={{ height: '100%' }}>

            <Typography variant='h6'>Selected</Typography>

            <Paper elevation={10} sx={{ height: '92%', overflowY: 'auto', pl: '20px', pt: '15px' }}>

                <List>

                    {quizList.map((contentItem) => {

                        return (

                            <ListItem
                                sx={{ mt: '5px' }}
                                key={contentItem.id}

                                disablePadding

                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={contentItem.label}
                                        src={generateImageUrlFromCDN(contentItem.startImageMetadata)}
                                    />
                                </ListItemAvatar>

                                <ListItemText sx={{ '.MuiListItemText-secondary': { color: 'orange', fontSize: '0.7em', fontWeight: 700 } }} primary={contentItem.label} secondary={contentItem.settings?.popular ? 'Popular' : ''} />

                                <ListItemSecondaryAction>


                                    <IconButton aria-label="up" size="small" onClick={() => notifyUp(contentItem)}><ArrowUpwardIcon fontSize="large" /></IconButton>
                                    <IconButton aria-label="down" size="small" onClick={() => notifyDown(contentItem)}><ArrowDownwardIcon fontSize="large" /></IconButton>
                                    <IconButton aria-label="remove" size="small" onClick={() => notifyRemove(contentItem)}><RemoveCircleOutlinedIcon fontSize="large" color="error" /></IconButton>

                                </ListItemSecondaryAction>


                            </ListItem>
                        );
                    })}

                </List >

            </Paper>
        </Box >

    );

};

export default CategoryPageSelectedList;