import React, { useState } from 'react';
import QuestionBank from "./QuestionBank";
import QuestionCanvas from "./QuestionCanvas";
import { Box, Tab, Tabs, Paper, Typography } from '@mui/material';



function QuestionEditor(props) {


    const questionData = props.questionData;
    const notifyDeleteQuestion = props.onDeleteQuestion;
    const notifyChangeQuestion = props.onChangeQuestion;
    const notifyUpDownQuestion = props.onUpDownQuestion;
    const notifyAddQuestion = props.onAddQuestion;
    const notifyCreateNewQuestion = props.onCreateNewQuestion;


    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };




    return (
        <Box sx={{ height: '100%' }}>

            <Box sx={{ display: 'flex', gap: '200px', align: 'flexStart' }}>
                <Typography variant="h6">Questions</Typography>

            </Box>

            <Box sx={{ height: 3 }}></Box>

            <Paper elevation={10} sx={{ height: '92%', overflowY: 'hidden', pl: '30px', pb: '20px', pr: '20px', pt: '10px' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Generated" index={0} ></Tab>
                    <Tab label="Selected" index={1} />

                </Tabs>

                <Box sx={{
                    height: '20px'
                }}></Box>


                <Box sx={{ height: '90%', display: (tabIndex === 0 ? 'block' : 'none') }}>

                    <QuestionBank onAddQuestion={notifyAddQuestion}></QuestionBank>
                </Box>

                <Box sx={{ height: '90%', display: (tabIndex === 1 ? 'block' : 'none') }}>

                    <QuestionCanvas questionData={questionData} onCreateNewQuestion={notifyCreateNewQuestion} onDeleteQuestion={notifyDeleteQuestion} onChangeQuestion={notifyChangeQuestion} onUpDownQuestion={notifyUpDownQuestion}></QuestionCanvas>


                </Box>
            </Paper>

        </Box >
    )

};

export default QuestionEditor;
