import { Box, Paper } from "@mui/material";
import SearchBox from "../quizzes/SearchBox";
import { useState } from "react";
import Publish from "./Publish";
import { getCategoryPathByTitle } from "./categoryUtil";

export default function SocialHub(props) {

    const [entity, setEntity] = useState(null);

    const handleEntitySelected = (entity) => {

        setEntity(entity);

    }




    return (

        <Box>

            <Box sx={{ width: '100%', display: 'flex', p: '20px', justifyContent: 'center' }}>
                <Box sx={{ width: '500px' }}>
                    <SearchBox endPoint={'page'} onEntitySelected={handleEntitySelected} resultMappingFunction={(r) => {
                        return {
                            label: r.label, id: r.id, type: r.type, seoTitle: r.type === 'CATEGORY' ? getCategoryPathByTitle(r.label.toLowerCase()) : r.seoTitle

                        };
                    }} />
                </Box>


            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                <Paper elevation={10} sx={{ borderRadius: '10px', width: '70%', padding: '40px' }}>

                    {entity && <Publish seoTitle={entity.seoTitle} type={entity.type} />}

                </Paper>

            </Box>

        </Box>


    )

}