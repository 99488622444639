import React, { useState } from 'react';

import { Box, Tabs, Tab } from '@mui/material';
import QuizAdmin from './quizzes/QuizAdmin.js';
import CategoryPageAdmin from './pages/CategoryPageAdmin';
import quizmydayLogo from "./quizmyday-logo.png";
import Publishing from './social-hub/SocialHub.js';
import SocialHub from './social-hub/SocialHub.js';





function MainEditor(props) {

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };




    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>

                <Box sx={{ height: '50px', mr: '30px' }}><img alt="quizmyday" height={50} src={quizmydayLogo}></img></Box>


                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Quizzes" index={0} ></Tab>

                    <Tab label="Category Pages" index={1} />
                    <Tab label="Social Hub" index={2} />
                </Tabs>


            </Box>
            <Box sx={{ display: (tabIndex === 0 ? 'block' : 'none') }}>
                <QuizAdmin />
            </Box>

            <Box sx={{ display: (tabIndex === 1 ? 'block' : 'none') }}>
                <CategoryPageAdmin />
            </Box>

            <Box sx={{ display: (tabIndex === 2 ? 'block' : 'none') }}>
                <SocialHub />
            </Box>



        </Box >

    )

};

export default MainEditor;
