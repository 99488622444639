import React, { useState } from 'react';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CircularProgress, Box, Typography, Paper, Button, TextField, Dialog, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { config } from "../Const.js";
import { executeFetch } from '../Util';
import PageManager from './CategoryPageManager';





function CategoryPageManagePanel({ pageContent, onLoad }) {

    const [id, setId] = useState(null);

    const [label, setLabel] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');

    const [createDate, setCreateDate] = useState(null);
    const [savePopupOpen, setSavePopupOpen] = useState(false);
    const [savePopupText, setSavePopupText] = useState("");
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [managePopupOpen, setManagePopupOpen] = useState(false);

    const closeSaveModal = () => setSavePopupOpen(false);
    const notifyCategoryPageLoaded = onLoad;




    const handleLoadClicked = () => {

        setManagePopupOpen(true);
    }


    const handleLoadCategoryPage = (categoryPage) => {

        setId(categoryPage.id);
        setLabel(categoryPage.label);
        setDescription(categoryPage.description);

        setCategory(categoryPage.tagList[0].label);

        setManagePopupOpen(false);
        setCreateDate(categoryPage.createDate);

        notifyCategoryPageLoaded(categoryPage);
    }

    const generateCategoryPage = () => {

        let categoryPage = {};
        categoryPage.id = id;
        categoryPage.label = label;
        categoryPage.ceList = pageContent;
        categoryPage.tagList = [{ 'label': category }]
        categoryPage.description = description;
        categoryPage.createDate = createDate;

        return categoryPage;

    }

    const handleSaveClicked = (e) => {

        let categoryPage = generateCategoryPage();

        setSavePopupOpen(true);
        setSavingInProgress(true);

        let formData = new FormData();
        formData.append('categoryPageJson', JSON.stringify(categoryPage));

        let url = config.SERVER_URL + "/api/v1/categorypage/admin/save";

        let responseHandler = (result) => {
            setSavingInProgress(false);

            // handleLoadCategoryPage(result);
            setTimeout(() => setSavePopupOpen(false), 1500);
            setSavePopupText("Saved Successfully");
        }

        executeFetch(url, "post", null, formData, null, responseHandler);

    };


    const handleNameChanged = (e) => {
        setLabel(e.target.value);
    }

    const handleDescriptionChanged = (e) => {
        setDescription(e.target.value);
    }


    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    }

    const closeManagePopup = () => setManagePopupOpen(false);


    return (

        <Box sx={{ height: '100%' }}>
            <Typography variant="h6">Manage Pages</Typography>
            <Box sx={{ height: 3 }}></Box>
            <Paper elevation={10} sx={{ height: '92%', overflowY: 'auto', padding: '20px' }}>

                <Box sx={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>

                    <Button variant="outlined" size="small" startIcon={<ManageSearchIcon />} onClick={handleLoadClicked}>Load</Button>
                    <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={handleSaveClicked}>Save</Button>


                </Box>

                <Box sx={{ height: '20px' }}></Box>

                <Box>



                    <Box sx={{ display: 'flex', gap: '5px', marginBottom: '20px', alignItems: 'flex-end' }}>
                        <TextField sx={{ width: '100%' }} disabled size="small" label="Name" value={label ? label : ''} variant="outlined" onChange={handleNameChanged}></TextField>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '5px', marginBottom: '20px' }}>


                        <FormControl size="small" sx={{ width: '100%' }}>
                            <InputLabel id="category-label">Category</InputLabel>
                            <Select disabled size="small"
                                labelId="category-label"
                                value={category}
                                label="Category"

                                onChange={handleCategoryChange}
                            >
                                <MenuItem value={'featured'}>Featured</MenuItem>
                                <MenuItem value={'classic rock'}>Classic Rock</MenuItem>
                                <MenuItem value={'pop'}>Pop</MenuItem>
                                <MenuItem value={'hard rock'}>Hard Rock</MenuItem>
                                <MenuItem value={'60s'}>60s</MenuItem>
                                <MenuItem value={'70s'}>70s</MenuItem>
                                <MenuItem value={'80s'}>80s</MenuItem>
                                <MenuItem value={'90s'}>90s</MenuItem>
                                <MenuItem value={'00s'}>00s</MenuItem>
                                <MenuItem value={'new wave'}>New Wave</MenuItem>
                                <MenuItem value={'alternative'}>Alternative</MenuItem>
                                <MenuItem value={'k-pop'}>K-Pop</MenuItem>
                                <MenuItem value={'country'}>Country</MenuItem>
                                <MenuItem value={'soul'}>Soul / R&B</MenuItem>
                                <MenuItem value={'hip hop'}>Hip Hop</MenuItem>
                                <MenuItem value={'latin'}>Latin</MenuItem>
                                <MenuItem value={'popular'}>Popular</MenuItem>


                            </Select>
                        </FormControl>


                    </Box>


                    <Box sx={{ display: 'flex', marginBottom: '20px', gap: '5px' }}>
                        <TextField disabled sx={{ width: '100%' }} size="small" label="Description" value={description ?? ''} variant="outlined" onChange={handleDescriptionChanged}></TextField>
                    </Box>


                </Box>

                <Dialog open={managePopupOpen} PaperProps={{
                    sx: {

                        maxWidth: 800,
                        width: 800,
                        height: 650,
                        overflowY: 'scroll'
                    },
                }} onClose={closeManagePopup}>
                    <PageManager onLoad={handleLoadCategoryPage} />
                </Dialog>


                <Dialog open={savePopupOpen} PaperProps={{
                    sx: {
                        width: 400,
                        height: 100

                    },
                }} onClose={closeSaveModal}>



                    <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                        {savingInProgress && <Box sx={{ padding: '20px' }}><CircularProgress /></Box>}

                        {!savingInProgress && <Box sx={{ display: 'flex' }}><CheckCircleIcon fontSize='large' sx={{ color: 'green', marginRight: '10px' }}></CheckCircleIcon>
                            <Typography variant="h6">{savePopupText}</Typography></Box>}


                    </Box>
                </Dialog>




            </Paper>

        </Box>
    );

};

export default CategoryPageManagePanel;
