
import React from 'react';
import { useState, useEffect } from 'react';
import { config } from "../Const.js";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, TableSortLabel, Button } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { styled } from '@mui/material/styles';
import { executeFetch } from '../Util.js';



function CategoryPageManager(props) {

    const notifyOnLoad = props.onLoad;

    const [pageList, setPageList] = useState([]);
    const [order, setOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('label');


    const ORDER_ASC = 'asc';
    const ORDER_DESC = 'desc';
    const ORDER_BY_LABEL = 'label';
    const ORDER_BY_MODIFY_DATE = 'modifyDate';



    const applyFiltersSort = () => {

        let pageListClone = JSON.parse(JSON.stringify(pageList));

        let comparator;


        switch (orderBy) {
            case ORDER_BY_LABEL:
                comparator = labelComparator;
                break;

            case ORDER_BY_MODIFY_DATE:
                comparator = dateComparator;
                break;

            default:

                break;
        }

        pageListClone.sort(comparator)
        return pageListClone;
    }





    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({

        backgroundColor: '#1565c0',
        color: 'white',

    }));




    const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({

        '&.MuiTableSortLabel-root': {
            color: 'white'
        }

    }));



    const handleSort = (property) => {

        const isAsc = orderBy === property && order === ORDER_ASC;
        setOrder(isAsc ? ORDER_DESC : ORDER_ASC);
        setOrderBy(property);

    };

    const handlePreviewClicked = (page) => {

        let url = config.APP_URL_HOST + '/quizSelection/byTag?title=' + page.tagList[0].label;
        window.open(url);


    }



    const labelComparator = (a, b) => {

        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        // Featured page always on top
        if (labelA === 'FEATURED') return -1;
        if (labelB === 'FEATURED') return 1;

        return getSortValue(labelA, labelB);

    };

    const getSortValue = (valueA, valueB) => {

        let value = 0;

        if (valueA < valueB) {
            value = -1;
        } else if (valueA > valueB) {
            value = 1;
        }

        if (order === ORDER_DESC) {
            value *= -1;
        }

        return value;

    }


    const dateComparator = (a, b) => {

        const valueA = a.modifyDate;
        const valueB = b.modifyDate;
        return getSortValue(valueA, valueB);
    };


    useEffect(() => {

        fetchPageList();

    }, []);




    const fetchPageList = () => {
        var timeout;

        if (timeout) clearTimeout(timeout);



        timeout = setTimeout(() => {

            let url = config.SERVER_URL + "/api/v1/categorypage/admin/findAll";

            let responseHandler = (pageList) => {
                setLoading(false);
                setPageList(pageList);

            }
            executeFetch(url, "get", null, null, null, responseHandler)


        }, 300);
    }


    let filteredPageList = applyFiltersSort();

    return (



        <Box>

            {loading && <Box sx={{ padding: '40px' }}><CircularProgress /></Box>}

            {!loading && <TableContainer>
                <Table size="small" aria-label="page table">
                    <TableHead>
                        <TableRow >

                            <StyledTableHeaderCell sx={{ width: 250 }}>
                                <StyledTableSortLabel direction={orderBy === 'label' ? order : 'asc'} onClick={() => handleSort('label')}>
                                    Label
                                </StyledTableSortLabel>
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell sx={{ width: 150 }}>
                                <StyledTableSortLabel direction={orderBy === 'category' ? order : 'asc'} onClick={() => handleSort('category')}>
                                    Category
                                </StyledTableSortLabel>
                            </StyledTableHeaderCell>


                            <StyledTableHeaderCell sx={{ width: 150 }}>
                                <StyledTableSortLabel direction={orderBy === 'modifyDate' ? order : 'asc'} onClick={() => handleSort('modifyDate')}>
                                    Modify Date
                                </StyledTableSortLabel>
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell sx={{ width: 130 }}></StyledTableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>




                        {filteredPageList.map((page) => {



                            return <StyledTableRow key={page.id}>
                                <TableCell>{page.label}</TableCell>
                                <TableCell>{page.tagList?.[0].label}</TableCell>

                                <TableCell>{page.modifyDate.substr(0, 10)} {page.modifyDate.substr(11, 5)}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: '10px' }}>
                                        <Button size="small" variant="contained" onClick={() => notifyOnLoad(page)}>Load</Button>
                                        <Button size="small" variant="outlined" onClick={() => handlePreviewClicked(page)}>Preview</Button>

                                    </Box>
                                </TableCell>


                            </StyledTableRow>
                        })}


                    </TableBody>
                </Table>
            </TableContainer >
            }

        </Box >


    )

}

export default CategoryPageManager;

