import React from 'react';
import { useState } from 'react';
import QuestionToolbar from './QuestionToolbar';




function BucketQuestion(props) {


    const data = props.data;
    const questionData = data.question;
    const criteriaData = data.criteria;
    const [answerHidden, setAnswerHidden] = useState(true);

    const handleVariableSelected = props.onVariableSelect;



    var optionList = questionData.optionList.map(function (o) {

        return (<span className={`bucketOption bucketOption${questionData.optionList.findIndex(obj => obj === o)}`}>{o.label}</span>);

    });


    const handleShowHideAnswer = () => {

        setAnswerHidden(!answerHidden);

    }




    var bucketItemList = questionData.bucketQuestionItemList.map(function (bqi) {

        return (
            <span className='bucketItem'>{bqi.label}

                {bqi.correctOptionList.map(co => <span className={answerHidden ? `` : `bucketOptionSmall bucketOption${questionData.optionList.findIndex(obj => obj.uuid === co.uuid)}`}>&nbsp;</span>


                )}


            </span>);

    });


    var questionText = questionData.textMessage;

    return (
        <div className='question'>

            <div className="questionHeaderContainer">
                <div className='questionHeader'>{questionText}</div>
                <QuestionToolbar data={criteriaData} onVariableSelect={handleVariableSelected} onShowHideAnswer={handleShowHideAnswer} answerHidden={answerHidden} />
            </div>

            <div className="bucketOptionContainer">Options: {optionList}</div>
            <div className="bucketItemContainer">
                <span className="bucketItemsTitle">Items:</span>
                <span className="bucketItemList">{bucketItemList}</span>
            </div>



        </div>
    )



};

export default BucketQuestion;
