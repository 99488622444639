import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from "@mui/material";

const ValidationDialog = ({ title, open, errors, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {errors?.length > 0 ? (
                    <List>
                        {errors?.map((error, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={error} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography>No validation errors.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ValidationDialog;
