import { Box, FormControlLabel, FormGroup, TextField } from "@mui/material";



export default function FacebookGroup({ fg, onChange, disabled }) {


    return (

        <Box sx={{ display: 'flex', alignItems: 'center' }}>

            <FormGroup>

                <FormControlLabel
                    control={
                        <TextField
                            sx={{ width: '200px' }}
                            disabled={disabled}
                            size="small"
                            value={fg?.groupId || ''}
                            onChange={(e) => onChange(e.target.value)}
                            label="Facebook Group"
                        />
                    }

                />


            </FormGroup>

            <Box sx={{ minWidth: '400px' }}>{fg?.url}</Box>


        </Box>


    )

}