import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainEditor from './MainEditor';
import QuizLogin from './login';
import CssBaseline from '@mui/material/CssBaseline';



const root = ReactDOM.createRoot(document.getElementById('root'));
const token = localStorage.getItem("token");


root.render(
  <>
    <CssBaseline />

    {!token && <QuizLogin />}
    {token && <MainEditor />}

  </>
);



