import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, Dialog, Button } from '@mui/material';
import { generateImageUrl } from '../Util.js'


function ImageCropper(props) {

    const [previewPopupMode, setPreviewPopupMode] = useState(false)


    const [crop, setCrop] = useState();
    const [resizeRatio, setResizeRatio] = useState(null);
    const [croppedImgUrl, setCroppedImgUrl] = useState(null);
    const imd = props.imd;
    const imgDesc = imd.description;
    const imgUrl = imd.url;
    const lockAspect = props.lockAspect;
    const { aspect, minWidth } = lockAspect;

    const handleImageSelected = props.onSelect;




    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const handlePreviewButtonClicked = () => {
        setPreviewPopupMode(true);
    }

    const handleSelectButtonClicked = () => {
        setPreviewPopupMode(false);

        if (crop) {
            imd.crop = { width: cr(crop.width), height: cr(crop.height), x: cr(crop.x), y: cr(crop.y) }
        }
        handleImageSelected(imd);

    }

    const cr = (num) => {
        return Math.round(num * resizeRatio);
    }


    const handleCropChange = (c) => {
        setCrop(c);
        setCroppedImgUrl(generateImageUrl(imgUrl, cr(c.x), cr(c.y), cr(c.width), cr(c.height)));
    }

    const handleImageLoaded = (e) => {
        let img = e.target;
        setResizeRatio(Math.min(img.naturalWidth / img.width), 1);
    }

    return (

        <Box sx={{ padding: '5px' }}>



            {lockAspect &&

                <ReactCrop crop={crop} onChange={c => handleCropChange(c)} aspect={aspect} minWidth={minWidth / resizeRatio} >
                    <img alt={imgDesc} src={imgUrl} onLoad={handleImageLoaded}></img>

                </ReactCrop>
            }

            {!lockAspect &&

                <ReactCrop crop={crop} onChange={c => handleCropChange(c)} >
                    <img alt={imgDesc} src={imgUrl} onLoad={handleImageLoaded}></img>

                </ReactCrop>
            }



            <Box className="cropperInfoDiv">
                {crop &&
                    <Box className="">Cropped: x={cr(crop.x)}, y={cr(crop.y)}, {cr(crop.width)} x {cr(crop.height)}</Box>
                }

                <Box className="wrapper"></Box>
                <Box className="cropperInfoButtonDiv"><Button variant='contained' onClick={handleSelectButtonClicked}>Select</Button></Box>
                <Box className="cropperInfoButtonDiv"><Button variant='outlined' onClick={handlePreviewButtonClicked}>Preview</Button></Box>
            </Box>
            {renderHTML(imgDesc)}

            {crop && previewPopupMode &&
                <Dialog open={previewPopupMode} onClose={() => setPreviewPopupMode(false)} PaperProps={{ sx: { width: Math.min(cr(crop.width), 700), height: Math.min(cr(crop.height), 800), padding: 10 } }}>
                    <img width={Math.min(cr(crop.width), 700)} src={croppedImgUrl} alt={imd.description}></img>

                </Dialog>
            }


        </Box >
    )

};

export default ImageCropper;
