import { Typography } from "@mui/material";



export default function XPost({ url }) {

    return (

        <Typography>URL: {url}</Typography>

    )

}