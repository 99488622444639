import React from 'react';
import { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

function QuestionOption(props) {


    const [optionReplaced, setOptionReplaced] = useState(false);
    const option = props.option;
    const optionType = props.type;
    const optionList = props.optionList;
    const editMode = props.editMode;

    const criteriaData = props.criteriaData;
    const answerHidden = props.answerHidden;

    const handleQVariableSelected = props.onQVariableSelect;

    const changeQVariableSelect = (e, qVariable) => {

        let newValue = JSON.parse(e.target.value);
        let oldValue = qVariable;
        handleQVariableSelected(newValue, oldValue, optionType);
    }


    const clickReplaceOption = (e) => {
        setOptionReplaced(!optionReplaced);
    }

    const clickRemoveOption = (e, origQVariable, optionType) => {
        handleQVariableSelected(null, origQVariable, optionType);
    }


    const generateQVariableSelect = (qVariableList, origQVariable) => {


        if (qVariableList) {

            var optionList = qVariableList.map((item) => {

                let itemLabel = item.label;


                return (<option selected={JSON.stringify(item.quizzlerObject) === JSON.stringify(origQVariable.quizzlerObject)} key={JSON.stringify(item)} className='variableSelectOption' value={JSON.stringify(item)} >
                    {itemLabel}
                </option>
                )
            });

            return (

                <select className={optionReplaced ? 'variableSelect' : 'variableSelectHidden'} onChange={(e) => changeQVariableSelect(e, origQVariable, optionType)}>
                    {optionList}

                </select>
            )
        }

    }



    const generateOption = (qVariableList, origQVariable) => {

        return (

            <Box>

                <Box sx={{ display: 'flex', height: 30 }}>

                    {optionType === 'DISTRACTOR' && editMode && optionList.length > 2 &&

                        <IconButton aria-label="remove" size="small" onClick={(e) => clickRemoveOption(e, origQVariable, optionType)}><DeleteOutlinedIcon fontSize="small" /></IconButton>

                    }

                    {optionType === 'DISTRACTOR' && editMode && optionList.length <= 2 &&

                        <Box style={{ width: 30 }}></Box>

                    }

                    {optionType === 'CORRECT_OPTION' && editMode &&

                        <Box style={{ width: 30 }}></Box>

                    }

                    {qVariableList && editMode &&


                        <IconButton sx={{ marginLeft: '-5px' }} aria-label="replace" size="small" onClick={clickReplaceOption}><ChangeCircleOutlinedIcon fontSize="small" /></IconButton>


                    }


                    {!qVariableList && editMode &&
                        <Box style={{ width: 26 }}></Box>
                    }



                    {qVariableList && editMode && generateQVariableSelect(qVariableList, origQVariable)}

                    {!optionReplaced && <Box sx={{ alignSelf: 'center' }}><Typography sx={!answerHidden && optionType === 'CORRECT_OPTION' ? { fontWeight: 'bold', color: 'green' } : {}}>{option.label}</Typography></Box>}


                </Box>

            </Box >

        )

    }


    return generateOption((optionType === 'CORRECT_OPTION' ? criteriaData.correctOptionQVariableList : criteriaData.distractorQVariableList), option.qv);






}

export default QuestionOption;