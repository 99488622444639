import React, { useState } from 'react';
import CategoryPageEditor from "./CategoryPageEditor";
import { Box } from '@mui/material';
import CategoryPageManagePanel from './CategoryPageManagePanel';



export default function CategoryPageAdmin(props) {

    const [page, setPage] = useState(null);
    const [pageContent, setPageContent] = useState([]);



    const handleCategoryPageLoaded = (page) => {
        setPage(page);
        setPageContent(page?.ceList);
    }

    const handlePageContentChanged = (contentList) => {

        setPageContent(contentList);
    }

    return (
        <Box sx={{ display: 'flex' }}>

            <Box sx={{ pr: '20px', pl: '20px', pt: '20px', mr: '15px', width: '30%', height: '94vh' }}>
                <CategoryPageManagePanel pageContent={pageContent} onLoad={handleCategoryPageLoaded} />
            </Box>

            <Box sx={{ pr: '20px', pl: '20px', pt: '20px', mr: '15px', width: '70%', height: '94vh' }}>
                <CategoryPageEditor page={page} onChange={handlePageContentChanged}></CategoryPageEditor>
            </Box>


        </Box>
    )

};
